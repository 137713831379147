// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    background-color: #000;
    color: #fff8f8;
    padding: 0.2rem 2rem;
    -webkit-user-select: none;
            user-select: none;
    font-size: 3rem;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/styles/main.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,cAAc;IACd,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".title {\n    background-color: #000;\n    color: #fff8f8;\n    padding: 0.2rem 2rem;\n    user-select: none;\n    font-size: 3rem;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
