// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_outerContainer__KRw1G {
    width: 100%;
    position: fixed;
    -webkit-user-select: none;
            user-select: none;
    background-color: #000000;
    color: #fff8f8;
    padding: 1rem 0;
}

.header_headerContainer__UM2qC {
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 2rem;
}

.header_headerItem__LxQrJ {
    flex-basis: 33%;
    text-align: center;
    cursor: pointer;
}

.header_menu__bsN3F {
    text-align: center;
    width: 100vw;
    position: absolute;
    background-color: #000000;
    color: #fff8f8;
    top: 60px;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.header_menuItem__UTNfg {
    width: 30%;
    padding: 0.2rem 0;
}

.header_menuItem__UTNfg:hover {
    color: #000000;
    background-color: #fff8f8;
}

.header_show__3lRGm {
    display: none;
}

@media (max-width: 1000px) {
    .header_show__3lRGm {
        display: block;
    }

    .header_hide__A9nlw {
        display: none;
    }

    .header_menuItem__UTNfg {
        width: 100% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,SAAS;IACT,OAAO;IACP,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".outerContainer {\n    width: 100%;\n    position: fixed;\n    user-select: none;\n    background-color: #000000;\n    color: #fff8f8;\n    padding: 1rem 0;\n}\n\n.headerContainer {\n    padding: 0 1rem;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    font-size: 2rem;\n}\n\n.headerItem {\n    flex-basis: 33%;\n    text-align: center;\n    cursor: pointer;\n}\n\n.menu {\n    text-align: center;\n    width: 100vw;\n    position: absolute;\n    background-color: #000000;\n    color: #fff8f8;\n    top: 60px;\n    left: 0;\n    z-index: 99999;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n}\n\n.menuItem {\n    width: 30%;\n    padding: 0.2rem 0;\n}\n\n.menuItem:hover {\n    color: #000000;\n    background-color: #fff8f8;\n}\n\n.show {\n    display: none;\n}\n\n@media (max-width: 1000px) {\n    .show {\n        display: block;\n    }\n\n    .hide {\n        display: none;\n    }\n\n    .menuItem {\n        width: 100% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `header_outerContainer__KRw1G`,
	"headerContainer": `header_headerContainer__UM2qC`,
	"headerItem": `header_headerItem__LxQrJ`,
	"menu": `header_menu__bsN3F`,
	"menuItem": `header_menuItem__UTNfg`,
	"show": `header_show__3lRGm`,
	"hide": `header_hide__A9nlw`
};
export default ___CSS_LOADER_EXPORT___;
