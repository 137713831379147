// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  height: 100%;
}

html,
body,
#root {
  height: 100%;
  animation: backgroundAnimation 20s linear infinite;
}

@keyframes backgroundAnimation {
  0% {
    background-color: #bad8e5;
  }
  50% {
    background-color: #cfe5ba;
  }
  100% {
    background-color: #bad8e5;
  }
}

* {
  font-family: 'mcoloniab', monospace;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;;;EAGE,YAAY;EACZ,kDAAkD;AACpD;;AAEA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".App {\n  text-align: center;\n  height: 100%;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  animation: backgroundAnimation 20s linear infinite;\n}\n\n@keyframes backgroundAnimation {\n  0% {\n    background-color: #bad8e5;\n  }\n  50% {\n    background-color: #cfe5ba;\n  }\n  100% {\n    background-color: #bad8e5;\n  }\n}\n\n* {\n  font-family: 'mcoloniab', monospace;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
