// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_aboutContainer__hXLZ1 {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: space-between;
}

.about_imageContainer__sV6-J {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.about_leftContainer__0kDmk {
    flex-basis: 32.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.about_rightContainer__Ndzt9 {
    flex-basis: 65%;
}

.about_textContainer__JAbJy {
    background-color: #000;
    padding: 0.4rem;
    color: #fff8f8;
}

@media (max-width: 1000px) {
    .about_hide__LNnEo {
        display: none;
    }

    .about_aboutContainer__hXLZ1 {
        flex-direction: column !important;
        width: 85% !important;
        margin-bottom: 4rem;
    }
    .about_leftContainer__0kDmk,
    .about_rightContainer__Ndzt9 {
        flex-basis: 100% !important;

    }
}`, "",{"version":3,"sources":["webpack://./src/screens/about/about.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,iCAAiC;QACjC,qBAAqB;QACrB,mBAAmB;IACvB;IACA;;QAEI,2BAA2B;;IAE/B;AACJ","sourcesContent":[".aboutContainer {\n    display: flex;\n    flex-direction: row;\n    width: 70%;\n    justify-content: space-between;\n}\n\n.imageContainer {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 1rem;\n}\n\n.leftContainer {\n    flex-basis: 32.5%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: left;\n}\n\n.rightContainer {\n    flex-basis: 65%;\n}\n\n.textContainer {\n    background-color: #000;\n    padding: 0.4rem;\n    color: #fff8f8;\n}\n\n@media (max-width: 1000px) {\n    .hide {\n        display: none;\n    }\n\n    .aboutContainer {\n        flex-direction: column !important;\n        width: 85% !important;\n        margin-bottom: 4rem;\n    }\n    .leftContainer,\n    .rightContainer {\n        flex-basis: 100% !important;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutContainer": `about_aboutContainer__hXLZ1`,
	"imageContainer": `about_imageContainer__sV6-J`,
	"leftContainer": `about_leftContainer__0kDmk`,
	"rightContainer": `about_rightContainer__Ndzt9`,
	"textContainer": `about_textContainer__JAbJy`,
	"hide": `about_hide__LNnEo`
};
export default ___CSS_LOADER_EXPORT___;
