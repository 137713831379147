// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cool_buttons_container__s--i0 {
    display: flex;
    justify-content: center;
    -webkit-user-select: none;
            user-select: none;
}

.cool_buttons_buttonContainer__OS67X {
    border: #fff8f8 3px solid;
    background-color: #000000;
    color: #fff8f8;
    font-size: 4rem;
    font-weight: 600;
    width: 80%;
    cursor: pointer;
}

.cool_buttons_normalAnimation__KDT4Z {
    animation: cool_buttons_coolButton__\\+tJLR 1s linear infinite;
}

.cool_buttons_reverseAnimation__EB-od {
    animation: cool_buttons_coolButtonReverse__CRuES 1s linear infinite;
}

.cool_buttons_buttonContainer__OS67X:hover {
    background-color: #fff8f8;
    color: #000000;
}

@keyframes cool_buttons_coolButton__\\+tJLR {
    0% {
        transform: rotateZ(calc(var(--cool-button-rotation) * -1));
    }

    49% {
        transform: rotateZ(calc(var(--cool-button-rotation) * -1));
    }

    50% {
        transform: rotateZ(var(--cool-button-rotation));
    }

    100% {
        transform: rotateZ(var(--cool-button-rotation));
    }
}

@keyframes cool_buttons_coolButtonReverse__CRuES {
    0% {
        transform: rotateZ(var(--cool-button-rotation));
    }

    49% {
        transform: rotateZ(var(--cool-button-rotation));
    }

    50% {
        transform: rotateZ(calc(var(--cool-button-rotation) * -1));
    }

    100% {
        transform: rotateZ(calc(var(--cool-button-rotation) * -1));
    }
}`, "",{"version":3,"sources":["webpack://./src/components/cool_buttons/cool_buttons.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,6DAAwC;AAC5C;;AAEA;IACI,mEAA+C;AACnD;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI;QACI,0DAA0D;IAC9D;;IAEA;QACI,0DAA0D;IAC9D;;IAEA;QACI,+CAA+C;IACnD;;IAEA;QACI,+CAA+C;IACnD;AACJ;;AAEA;IACI;QACI,+CAA+C;IACnD;;IAEA;QACI,+CAA+C;IACnD;;IAEA;QACI,0DAA0D;IAC9D;;IAEA;QACI,0DAA0D;IAC9D;AACJ","sourcesContent":[".container {\n    display: flex;\n    justify-content: center;\n    user-select: none;\n}\n\n.buttonContainer {\n    border: #fff8f8 3px solid;\n    background-color: #000000;\n    color: #fff8f8;\n    font-size: 4rem;\n    font-weight: 600;\n    width: 80%;\n    cursor: pointer;\n}\n\n.normalAnimation {\n    animation: coolButton 1s linear infinite;\n}\n\n.reverseAnimation {\n    animation: coolButtonReverse 1s linear infinite;\n}\n\n.buttonContainer:hover {\n    background-color: #fff8f8;\n    color: #000000;\n}\n\n@keyframes coolButton {\n    0% {\n        transform: rotateZ(calc(var(--cool-button-rotation) * -1));\n    }\n\n    49% {\n        transform: rotateZ(calc(var(--cool-button-rotation) * -1));\n    }\n\n    50% {\n        transform: rotateZ(var(--cool-button-rotation));\n    }\n\n    100% {\n        transform: rotateZ(var(--cool-button-rotation));\n    }\n}\n\n@keyframes coolButtonReverse {\n    0% {\n        transform: rotateZ(var(--cool-button-rotation));\n    }\n\n    49% {\n        transform: rotateZ(var(--cool-button-rotation));\n    }\n\n    50% {\n        transform: rotateZ(calc(var(--cool-button-rotation) * -1));\n    }\n\n    100% {\n        transform: rotateZ(calc(var(--cool-button-rotation) * -1));\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `cool_buttons_container__s--i0`,
	"buttonContainer": `cool_buttons_buttonContainer__OS67X`,
	"normalAnimation": `cool_buttons_normalAnimation__KDT4Z`,
	"coolButton": `cool_buttons_coolButton__+tJLR`,
	"reverseAnimation": `cool_buttons_reverseAnimation__EB-od`,
	"coolButtonReverse": `cool_buttons_coolButtonReverse__CRuES`
};
export default ___CSS_LOADER_EXPORT___;
