// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job_section_container__A2eWS {
    background-color: #000;
    color: #fff8f8;
    margin: 0.4rem 0;
    text-align: left;
    width: 50%;
    padding: 0.4rem;
}

@media (max-width: 1000px) {
    .job_section_container__A2eWS {
        width: 85% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/screens/experience/components/job_section/job_section.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".container {\n    background-color: #000;\n    color: #fff8f8;\n    margin: 0.4rem 0;\n    text-align: left;\n    width: 50%;\n    padding: 0.4rem;\n}\n\n@media (max-width: 1000px) {\n    .container {\n        width: 85% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `job_section_container__A2eWS`
};
export default ___CSS_LOADER_EXPORT___;
