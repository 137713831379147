// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infinite_text_infiniteAnimation__7kdLu {
    animation: infinite_text_scrollAnimation__12fVJ 120s linear infinite;
}

.infinite_text_infiniteAnimationRight__W\\+7b3 {
    animation: infinite_text_scrollAnimationRight__MUczH 120s linear infinite;
}

.infinite_text_infiniteAnimation__7kdLu,
.infinite_text_infiniteAnimationRight__W\\+7b3 {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-align: left;
}

.infinite_text_text__6XXgt {
    font-size: 4.7rem;
    font-weight: 700;
}

@media (max-width: 1000px) {
    .infinite_text_text__6XXgt {
        font-size: 2rem !important;
    }
}

@keyframes infinite_text_scrollAnimation__12fVJ {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-1200%);
    }
}

@keyframes infinite_text_scrollAnimationRight__MUczH {
    0% {
        transform: translateX(-1200%);
    }
    100% {
        transform: translateX(0%);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/infinite_text/infinite_text.module.css"],"names":[],"mappings":"AAAA;IACI,oEAA+C;AACnD;;AAEA;IACI,yEAAoD;AACxD;;AAEA;;IAEI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,6BAA6B;IACjC;AACJ;;AAEA;IACI;QACI,6BAA6B;IACjC;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".infiniteAnimation {\n    animation: scrollAnimation 120s linear infinite;\n}\n\n.infiniteAnimationRight {\n    animation: scrollAnimationRight 120s linear infinite;\n}\n\n.infiniteAnimation,\n.infiniteAnimationRight {\n    display: flex;\n    flex-wrap: nowrap;\n    white-space: nowrap;\n    text-align: left;\n}\n\n.text {\n    font-size: 4.7rem;\n    font-weight: 700;\n}\n\n@media (max-width: 1000px) {\n    .text {\n        font-size: 2rem !important;\n    }\n}\n\n@keyframes scrollAnimation {\n    0% {\n        transform: translateX(0);\n    }\n    100% {\n        transform: translateX(-1200%);\n    }\n}\n\n@keyframes scrollAnimationRight {\n    0% {\n        transform: translateX(-1200%);\n    }\n    100% {\n        transform: translateX(0%);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infiniteAnimation": `infinite_text_infiniteAnimation__7kdLu`,
	"scrollAnimation": `infinite_text_scrollAnimation__12fVJ`,
	"infiniteAnimationRight": `infinite_text_infiniteAnimationRight__W+7b3`,
	"scrollAnimationRight": `infinite_text_scrollAnimationRight__MUczH`,
	"text": `infinite_text_text__6XXgt`
};
export default ___CSS_LOADER_EXPORT___;
