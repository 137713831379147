// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_buttons_container__RroQ5 {
    background-color: #000;
    color: #fff8f8;
    display: flex;
    width: 80%;
    -webkit-user-select: none;
            user-select: none;
    margin: 0.5rem 0;
    padding: 0.5rem 0;
    cursor: pointer;
    transition-property: width, padding-top, padding-bottom, margin-top, margin-bottom;
    transition-duration: 0.2s;
    transition-timing-function: linear;
}

.home_buttons_container__RroQ5:hover {
    width: 300px !important;
    padding: 0.8rem 0 !important;
    margin: 0.2rem 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/home_buttons/home_buttons.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,cAAc;IACd,aAAa;IACb,UAAU;IACV,yBAAiB;YAAjB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,kFAAkF;IAClF,yBAAyB;IACzB,kCAAkC;AACtC;;AAEA;IACI,uBAAuB;IACvB,4BAA4B;IAC5B,2BAA2B;AAC/B","sourcesContent":[".container {\n    background-color: #000;\n    color: #fff8f8;\n    display: flex;\n    width: 80%;\n    user-select: none;\n    margin: 0.5rem 0;\n    padding: 0.5rem 0;\n    cursor: pointer;\n    transition-property: width, padding-top, padding-bottom, margin-top, margin-bottom;\n    transition-duration: 0.2s;\n    transition-timing-function: linear;\n}\n\n.container:hover {\n    width: 300px !important;\n    padding: 0.8rem 0 !important;\n    margin: 0.2rem 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `home_buttons_container__RroQ5`
};
export default ___CSS_LOADER_EXPORT___;
